<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      d="M11.0878 11.0853C11.0878 10.618 10.7093 10.2392 10.2424 10.2392C9.77549 10.2392 9.397 10.618 9.397 11.0853V11.7199H8.9743C8.50741 11.7199 8.12892 12.0988 8.12892 12.5661C8.12892 13.0334 8.50741 13.4122 8.9743 13.4122H9.397V14.6815H8.9743C8.50741 14.6815 8.12892 15.0603 8.12892 15.5276C8.12892 15.995 8.50741 16.3738 8.9743 16.3738H9.397V17.0084C9.397 17.4757 9.77549 17.8546 10.2424 17.8546C10.7093 17.8546 11.0878 17.4757 11.0878 17.0084V11.0853Z"
    />
    <path
      d="M13.5225 11.0853C13.5225 10.618 13.144 10.2392 12.6771 10.2392C12.2102 10.2392 11.8317 10.618 11.8317 11.0853V17.0084C11.8317 17.4757 12.2102 17.8546 12.6771 17.8546C13.144 17.8546 13.5225 17.4757 13.5225 17.0084V16.3738H13.9452C14.4121 16.3738 14.7906 15.995 14.7906 15.5276C14.7906 15.0603 14.4121 14.6815 13.9452 14.6815H13.5225V13.4122H13.9452C14.4121 13.4122 14.7906 13.0334 14.7906 12.5661C14.7906 12.0988 14.4121 11.7199 13.9452 11.7199H13.5225V11.0853Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.57619 7.6358C7.17551 7.6358 6.04003 8.7723 6.04003 10.1743V17.9215C6.04003 19.3234 7.17551 20.4599 8.5762 20.4599H14.3412C15.7419 20.4599 16.8774 19.3234 16.8774 17.9215V10.1743C16.8774 8.77231 15.7419 7.6358 14.3412 7.6358H8.57619ZM7.73081 10.1743C7.73081 9.70694 8.1093 9.32811 8.57619 9.32811H14.3412C14.8081 9.32811 15.1866 9.70694 15.1866 10.1743V17.9215C15.1866 18.3888 14.8081 18.7676 14.3412 18.7676H8.5762C8.1093 18.7676 7.73081 18.3888 7.73081 17.9215V10.1743Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.11462 1C4.68146 1 3.5 2.15273 3.5 3.59628V20.4037C3.5 21.8484 4.68257 22.9965 6.11117 22.9965H17.8085C17.8338 22.9988 17.8595 23 17.8854 23C19.3185 23 20.5 21.8473 20.5 20.4037V7.17248C20.5 6.85589 20.3933 6.55506 20.2022 6.31155C19.8248 5.83052 19.0098 4.73001 18.2285 3.67505C17.7971 3.09247 17.3759 2.52371 17.0444 2.08093C16.5305 1.39501 15.7261 1 14.8746 1H6.11462ZM18.8092 20.4037C18.8092 20.8755 18.4331 21.2775 17.9414 21.3061C17.9229 21.3049 17.9042 21.3042 17.8854 21.3042H6.11117C5.59466 21.3042 5.19077 20.8922 5.19077 20.4037V3.59628C5.19077 3.10675 5.59576 2.69231 6.11462 2.69231H14.8746C15.2025 2.69231 15.502 2.84301 15.6916 3.09601C16.0064 3.51656 16.4153 4.0687 16.8399 4.64201C17.5928 5.6585 18.3952 6.74198 18.8092 7.27569V20.4037Z"
    />
  </svg>
</template>
